// import { API_URL } from "react-native-dotenv";
import statusSuccessChecker from "./statusSuccessChecker";

const userAccessTokenWithGoogle = async (serverAuthCode) => {
  let API_URL = "https://prod-1.api.grandmind.co/api/v1";

  try {
    const response = await fetch(
      `${API_URL}/accounts/oauth?access_token=${serverAuthCode}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = await response.json();

    if (statusSuccessChecker(responseData.status_code)) {
      return {
        status: responseData.status_code,
        data: responseData.data,
      };
    } else if (responseData.status_code === 404) {
      return {
        status: responseData.status_code,
        message: "There is no user with this email address",
      };
    } else {
      return {
        status: responseData.status_code,
        message: "Error: " + responseData.error,
      };
    }
  } catch (error) {
    return {
      status: 404,
      message: "Error requesting Google Login, Check your network connection",
    };
  }
};

export default userAccessTokenWithGoogle;
