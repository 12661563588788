import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import useStore from "../store/store";

export default function Navigation() {
  let showDeletePage = useStore((state) => state.showDeletePage);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="grandai/delete-account/" element={<Home />} />
        <Route
          path="grandai/delete-account/delete"
          element={showDeletePage ? <Login /> : <Navigate to="/" replace />}
        />
        {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
      </Routes>
    </BrowserRouter>
  );
}
