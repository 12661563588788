import React, { useEffect, useState } from "react";
import "./Home.css";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import userAccessTokenWithGoogle from "../scripts/userAccessTokenWithGoogle";
import userAccessTokenWithEmail from "../scripts/userAccessTokenWithEmail"; // You need to implement this function
import statusSuccessChecker from "../scripts/statusSuccessChecker";
import useStore from "../store/store";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import GoogleIcon from "@mui/icons-material/Google";
import myData from "../data/myData";

export default function Home() {
  let setUserAccessToken = useStore((state) => state.setUserAccessToken);
  let setShowDeletePage = useStore((state) => state.setShowDeletePage);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertIcon, setAlertIcon] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginWithGoogleFunc = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      loginRequestFunc(credentialResponse.access_token);
    },
    onError: () => {
      displayAlert(myData.errorLoginWithGoogle, "error");
    },
    onNonOAuthError: () => {
      displayAlert(myData.errorLoginWithGoogle, "error");
    },
  });

  const loginRequestFunc = async (accessToken) => {
    try {
      const response = await userAccessTokenWithGoogle(accessToken);

      if (statusSuccessChecker(response.status)) {
        await setUserAccessToken(response.data.access);
        await setShowDeletePage(true);
        navigate(
          `/grandai/delete-account/delete?token=${response.data.access}`
        );
      } else {
        displayAlert(response.message, "error");
      }
    } catch (error) {
      displayAlert(error.message, "error");
    }
  };

  const loginWithEmailFunc = async () => {
    if (!email) {
      displayAlert(myData.noEmailLoginWithEmail, "error");
      return;
    }

    if (!validateEmail(email)) {
      displayAlert(myData.invalidEmailFormatLoginWithEmail, "error");
      return;
    }

    if (!password) {
      displayAlert(myData.noPasswordLoginWithEmail, "error");
      return;
    }

    if (password.length < 8) {
      displayAlert(myData.passwordTooShortLoginWithEmail, "error");
      return;
    }

    try {
      const response = await userAccessTokenWithEmail(email, password);

      if (statusSuccessChecker(response.status)) {
        await setUserAccessToken(response.data.access);
        await setShowDeletePage(true);
        navigate(
          `/grandai/delete-account/delete?token=${response.data.access_token}`
        );
      } else {
        displayAlert(response.message, "error");
      }
    } catch (error) {
      displayAlert(error.message, "error");
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const displayAlert = (message, icon) => {
    setAlertText(message);
    setAlertIcon(icon);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setAlertText("");
      setAlertIcon("");
    }, 5000);
  };

  useEffect(() => {
    googleLogout();
  }, []);

  return (
    <div className="containerHome">
      <div className="containerBoxHome">
        <img src="./logo.png" alt="logo" className="logoHome" />
        <p className="firstTextHome">{myData.topBoxText}</p>
        <ol className="olHome">
          <li className="liHome">{myData.item1List}</li>
          <li className="liHome">{myData.item2List}</li>
        </ol>
        <input
          className="inputsHome"
          type="email"
          placeholder={myData.loginWithEmailPlaceHolderEmail}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="inputsHome"
          type="password"
          placeholder={myData.loginWithEmailPlaceHolderPassword}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          type="button"
          className="login-with-email-btn"
          onClick={loginWithEmailFunc}
        >
          <EmailIcon className="loginBtnEmailIconHome" fontSize="medium" />
          <p className="loginBtnTextHome">{myData.loginWithEmailButton}</p>
        </button>
        <button
          type="button"
          className="login-with-google-btn"
          onClick={loginWithGoogleFunc}
        >
          <GoogleIcon className="loginBtnIconHome" fontSize="medium" />
          <p className="loginBtnTextHome">{myData.loginWithGoogleButton}</p>
        </button>
        <p className="contactSupportTextHome">{myData.bottomPageRule}</p>
      </div>
      {showAlert && (
        <Alert className="alertBoxHome" severity={alertIcon}>
          {alertText}
        </Alert>
      )}
    </div>
  );
}
