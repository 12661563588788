import React, { useEffect, useState } from "react";
import "./Login.css";
import deleteAccount from "../scripts/deleteAccount";
import useStore from "../store/store";
import { Alert } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import statusSuccessChecker from "../scripts/statusSuccessChecker";
import myData from "../data/myData";

export default function Login() {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertIcon, setAlertIcon] = useState("");
  const [userAccessToken, setUserAccessToken] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  let setShowDeletePage = useStore((state) => state.setShowDeletePage);

  const handleConfirmDeleteChange = () => {
    setConfirmDelete(!confirmDelete);
  };

  const handleDeleteAccount = async () => {
    if (confirmDelete) {
      try {
        const response = await deleteAccount(userAccessToken);

        if (statusSuccessChecker(response.status)) {
          setAlertText(myData.alertAccountDeleted);
          setAlertIcon("success");
          setShowAlert(true);
          setTimeout(async () => {
            setUserAccessToken("");
            await setShowDeletePage(false);
            setShowAlert(false);
            setAlertText("");
            setAlertIcon("");
            navigate("/grandai/delete-account/");
          }, 5000);
        } else {
          setAlertText(response.message);
          setAlertIcon("error");
          setShowAlert(true);
          setTimeout(async () => {
            setShowAlert(false);
            setAlertText("");
            setAlertIcon("");
          }, 5000);
        }
      } catch (error) {
        setAlertText(error.message);
        setAlertIcon("error");
        setShowAlert(true);
        setTimeout(async () => {
          setShowAlert(false);
          setAlertText("");
          setAlertIcon("");
        }, 5000);
      }
    } else {
      alert(myData.alertConfirmAccountDelete);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    if (token) {
      setUserAccessToken(token);
    }
  }, []);

  return (
    <div className="containerLogin">
      <div className="containerBoxLogin">
        <h1 className="titleLogin">{myData.titleText}</h1>
        <p className="rulesLogin">{myData.rulesText}</p>
        <div>
          <label className="labelLogin">
            <input
              type="checkbox"
              checked={confirmDelete}
              onChange={handleConfirmDeleteChange}
              className="checkboxLogin"
            />
            {myData.confirmCheBox}
          </label>
        </div>
        <button
          disabled={!confirmDelete}
          onClick={handleDeleteAccount}
          className="deleteAccountBtnLogin"
        >
          {myData.deleteAccBtn}
        </button>
      </div>
      {showAlert && (
        <Alert className="alertBoxLogin" severity={alertIcon}>
          {alertText}
        </Alert>
      )}
    </div>
  );
}
