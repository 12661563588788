import { create } from "zustand";

const useStore = create((set) => ({
  userAccessToken: "",
  setUserAccessToken: () => set((value) => ({ userAccessToken: value })),
  showDeletePage: false,
  setShowDeletePage: () => set((value) => ({ showDeletePage: value })),
}));

export default useStore;
