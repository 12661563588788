let myData = {
  // Login Page
  errorLoginWithGoogle: "Couldn't  log in with Google",
  noEmailLoginWithEmail: "Please enter your email address",
  invalidEmailFormatLoginWithEmail: "Invalid email address",
  noPasswordLoginWithEmail: "Please enter your password",
  passwordTooShortLoginWithEmail: "Incorrect password",
  topBoxText:
    "To delete your Grand AI account, please login with your email and password or Google account to verify your identity Once your account is deleted, all associated information will be permanently removed, including:",
  item1List: "Your email address",
  item2List: "Your chat history",
  loginWithEmailPlaceHolderEmail: "Email",
  loginWithEmailPlaceHolderPassword: "Password",
  loginWithEmailButton: "Sign in with Email",
  loginWithGoogleButton: "Sign in with Google",
  bottomPageRule:
    "If you cannot log in or have any other questions, you can contact our support team at privacy@grandmind.co",
  // Delete Account Page
  alertAccountDeleted: "Your account has been deleted from our system",
  alertConfirmAccountDelete: "Please confirm your account deletion.",
  titleText: "Are you sure you want to delete your account?",
  rulesText:
    "Before we proceed, we want to ensure that you fully understand the implications of this action. Once your account is deleted, all of your information will be permanently removed from our system. This includes, but is not limited to: Email Address: The email address associated with your account will be erased, and you will no longer be able to use it to log in or recover your account. Chat History: All chat records and history will be permanently deleted. This means you will lose access to any conversations, messages, and files shared through our platform. Please be aware that this process is irreversible. Once your account is deleted, we will not be able to recover any of your information or data.",
  confirmCheBox: "I confirm that I want to delete my account",
  deleteAccBtn: "Delete Account",
};

export default myData;
