// import { API_URL } from "react-native-dotenv";
import statusSuccessChecker from "./statusSuccessChecker";

const deleteAccount = async (accessToken) => {
  let API_URL = "https://prod-1.api.grandmind.co/api/v1";

  console.log(accessToken);

  try {
    const response = await fetch(`${API_URL}/accounts/delete/`, {
      method: "DELETE", // 'GET', 'PUT', 'DELETE', 'POST'
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });

    if (response.status == 204) {
      return {
        status: 204,
        message: "Account deleted successfully",
      };
    }
    const responseData = await response.json();

    if (statusSuccessChecker(responseData.status_code)) {
      return {
        status: responseData.status_code,
        data: responseData.data,
      };
    } else {
      return {
        status: responseData.status_code,
        message: "Error: " + responseData.error,
      };
    }
  } catch (error) {
    return {
      status: 404,
      message: "Error Delete Account, Check your network connection",
    };
  }
};

export default deleteAccount;
