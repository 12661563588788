// App.js
import React from "react";
import Navigation from "./navigations/Navigation";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <GoogleOAuthProvider clientId="457484676869-5i40kp5cnrg660jaasc7ij0ctk6cdp24.apps.googleusercontent.com">
      <Navigation />
    </GoogleOAuthProvider>
  );
}

export default App;
